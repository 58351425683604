import * as React from "react"

const loadingSpinner = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={200}
        height={200}
        preserveAspectRatio="xMidYMid"
        style={{
            shapeRendering: "auto",
            display: "block",
            background: "0 0",
            width: "100px",
            margin: "0 auto",
            animation: "0.8s linear infinite rotate",
            pointerEvents: "none",
            zIndex: "-1",
            opacity: "1",
            transition: "all 0.2s",
        }}
        viewBox="0 0 100 100"
        {...props}
    >
        <style>
            {`
                @keyframes rotate {
                    from {
                        transform: rotate(0);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            `}
        </style>
        <g data-idx={1}>
            <circle
                cx={50}
                cy={50}
                r={35}
                fill="none"
                stroke="#000"
                strokeDasharray="164.93361431346415 56.97787143782138"
                strokeWidth={10}
                data-idx={2}
                transform="rotate(-158.4 50 50)"
            />
        </g>
    </svg>
)
export default loadingSpinner
